import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero404Page from "../components/pages/shared/hero/404-hero"

const NotFoundPage = () => (
  <Layout page={"404"}>
    <SEO title="404 - Не найдено"/>
    <Hero404Page color={"red"}/>
  </Layout>
)

export default NotFoundPage
