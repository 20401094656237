import React from "react"

import styles from "./hero-404-page.module.scss"
import { Link } from "gatsby"

const Hero404Page = () => (
  <div className={styles.hero}>
    <div className={`container ${styles.container}`}>
      <div className={styles.info}>
        <h1 className={styles.title}>Хм, кажется, здесь что-то было...</h1>
        <p className={styles.description}>Но, похоже, мы не можем найти то, что вам нужно.</p>
        <div className={styles.ctaContainer}>
          <Link to={"/"} className={styles.cta}>Перейти на главную</Link>
        </div>
      </div>
      <div className={styles.image}>
        <img alt='404 Страница не найдена' src={require("../../../../../images/cover/404.png")}/>
      </div>
    </div>
  </div>
)

export default Hero404Page
